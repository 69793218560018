const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://mmtaxi.dvgeo.app' : 'http://192.168.1.106:3012',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://mmtaxi.dvgeo.app' : 'http://192.168.1.106:3012',
    api: 'api/',
    apiSocket: 'mmtaxi::1.2',
    nameDir: 'mmtaxi',
    package: 'app.dvgeo.mmtaxi.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyB-PZDxZw5YVaESLR183grxM6jN2smz0FE',
    appName: 'Mi Móvil Taxi',
    provider: 'Devjos CIA LTDA',
    colorPrimary: '#ffc603',
    colorDark: '#ffa900',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'app.dvgeo.mmtaxi.passenger',
    playStoreDriverId: 'app.dvgeo.mmtaxi.driver',
    appStorePassengerId: '6445877957',
    appStoreDriverId: '6445878103',
    email: "Krizthx3@gmail.com",
};
export default config;
